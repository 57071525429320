<template>
  <v-container fluid class="px-lg-5">
    <app-header :title="title" :breadcrumbs="visibleBreadcrumbs" disable-button />
    <div class="row">
      <div class="col-md-9 col-sm-12">
        <v-card class="rounded-card" flat outlined>
          <v-card-text class="mt-5 mb-3 px-12">
            <app-form ref="form" :fields="fields"
              :submitButton="submitButton"
              @submitted="handleSubmit"
              @selection-changed="onSelectionChange"
              @date-changed="onDateChange"
              @time-changed="onTimeChange"
              disableCancelButton
            />
          </v-card-text>
        </v-card>
      </div>
      <div class="col-md-3 col-sm-12">
        <div class="col-12 mb-4 py-0 px-0" v-for="(userLeave, index) in userLeaves.items" :key="userLeave.id">
          <leave-card :userLeave="userLeave" :index="index" :canUpdateCard="false" />
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from 'moment';
import {mapActions, mapGetters} from 'vuex';
import LeaveCard from 'components/leaves/Card.vue';
import {
  permissionsList,
  leaveUnits,
  userLeaveUnits
} from 'constants';

export default {
  name: 'newLeaveRequest',
  components: {LeaveCard},
  data() {
    return {
      previousRoute: null,
      isEditMode: false,
      selectedUserId: null,
      unit: '',
      units: [],
      title: {
        text: this.$t('layout.accountNavMenu.requestNewLeave'),
        icon: 'mdi-calendar-plus'
      },
      fields: {
        userId: {
          type: 'select',
          key: 'userId',
          value: '',
          options: [],
          label: this.$tc('models.user.entity', 1),
          trackable: true,
          itemText: 'text',
          itemValue: 'key',
          classes: 'col-md-12 py-0',
          disabled: true,
          serverErrors: []
        },
        userLeaveId: {
          type: 'select',
          key: 'userLeaveId',
          value: '',
          options: [],
          label: this.$t('models.userLeave.attributes.leaveType'),
          rules: [{name: 'requiredSelect'}],
          trackable: true,
          itemText: 'text',
          itemValue: 'key',
          classes: 'col-lg-6 col-md-12 py-0',
          serverErrors: []
        },
        unit: {
          type: 'select',
          key: 'unit',
          value: '',
          options: [],
          label: this.$t('models.leaveRequest.attributes.unit'),
          rules: [{name: 'requiredSelect'}],
          trackable: true,
          itemText: 'text',
          itemValue: 'key',
          classes: 'col-lg-6 col-md-12 py-0',
          hidden: false,
          serverErrors: []
        },
        rangeDate: {
          type: 'rangeDatepicker',
          key: 'rangeDate',
          placeholder: this.$t('models.leaveRequest.attributes.date'),
          label: this.$t('models.leaveRequest.attributes.date'),
          value: [],
          rules: [],
          classes: 'col-lg-12 col-md-12 py-0',
          hidden: true,
          serverErrors: []
        },
        date: {
          type: 'datepicker',
          key: 'date',
          value: '',
          placeholder: this.$t('models.leaveRequest.attributes.date'),
          label: this.$t('models.leaveRequest.attributes.date'),
          rules: [],
          trackable: true,
          classes: 'col-lg-12 col-md-12 py-0',
          hidden: true,
          serverErrors: []
        },
        startTime: {
          type: 'timepicker',
          key: 'startTime',
          value: '',
          label: this.$t('models.leaveRequest.attributes.from'),
          placeholder: this.$t('models.leaveRequest.attributes.from'),
          rules: [],
          trackable: true,
          classes: 'col-lg-6 col-md-12 py-0',
          hidden: true,
          serverErrors: []
        },
        endTime: {
          type: 'timepicker',
          key: 'endTime',
          value: '',
          label: this.$t('models.leaveRequest.attributes.to'),
          placeholder: this.$t('models.leaveRequest.attributes.to'),
          rules: [],
          trackable: true,
          classes: 'col-lg-6 col-md-12 py-0',
          hidden: true,
          serverErrors: []
        }
      },
      submitButton: {
        label: this.$t('actions.submit')
      }
    };
  },
  computed: {
    ...mapGetters(['users', 'currentUser', 'userLeaves', 'isAdmin', 'userLeavesRequest']),
    breadcrumbs() {
      return [{
        text: this.$t('layout.mainMenu.home'),
        to: {name: 'Home'},
        show: true
      },
      {
        text: this.$t('layout.mainMenu.leaves'),
        to: this.leavesPath,
        show: true,
        exact: true
      },
      {
        text: this.$route.params.id,
        show: this.isEditMode,
        disabled: true
      },
      {
        text: this.isEditMode ? this.$t('views.leaves.edit') : this.$t('views.leaves.new'),
        to: this.isEditMode ? {name: 'EditLeaveRequest'} : {name: 'NewLeaveRequest'},
        exact: true,
        show: true
      }];
    },
    leavesPath() {
      if (this.isAdmin || this.fields.userId.value === this.currentUser.id) {
        return {
          name: 'UserLeaves',
          params: {uuid: this.fields.userId.value || this.currentUser.id}
        };
      } else {
        return {name: 'LeavesRequests'};
      }
    },
    visibleBreadcrumbs() {
      return this.breadcrumbs.filter((breadcrumb) => breadcrumb.show);
    }
  },
  methods: {
    ...mapActions(['getUsers', 'getUserLeaves', 'getLeaveRequest', 'requestNewLeave', 'setPermission', 'updateLeaveRequest']),
    userOptions() {
      this.getUsers({fields: ['firstName', 'lastName', 'office', 'workingHoursPerDay'], sortBy: ['firstName']}).then(() => {
        this.fields.userId.options = this._.map(this.users.items, (user) => ({key: `${user.id}`, text: user.name}));
        this.fields.userId.value = this.selectedUserId;
        this.fields.userId.disabled = (+this.users.count <= 1 || this.isEditMode);
      });
    },
    userLeaveOptions() {
      this.getUserLeaves({uuid: this.selectedUserId}).then(()=> {
        this.fields.userLeaveId.options = this.userLeaves.items.map((userLeave) => {
          return {key: `${userLeave.id}`, text: userLeave.name};
        });

        this.fields.userLeaveId.value = this.isEditMode ? `${this.userLeavesRequest.userLeaveId}` : '';
      });
    },
    onSelectionChange($event) {
      if ($event.key === 'userId') {
        this.clearInlineServerErrors(this.fields);
        this.selectedUserId = $event.value;
        this.userLeaveOptions();
        this.fields.userLeaveId.value = '';
        this.$refs.form.resetValidation();
        ['unit', 'date', 'endTime', 'startTime', 'rangeDate'].forEach((field) => this.hideField(field));
      } else if ($event.key === 'userLeaveId') {
        ['date', 'endTime', 'startTime', 'rangeDate'].forEach((field) => this.hideField(field));
        this.fields.unit.options = [];
        this.fields.unit.value = '';
        this.$refs.form.resetValidation();
        const units = this.userLeaves.items.find(({id}) => id === $event.value).unit;
        units.forEach((unit) => {
          if (unit === leaveUnits.HALF_DAY) {
            this.fields.unit.options.push({
              text: this.$t(`models.leaveRequest.attributes.units.${userLeaveUnits.HALF_DAY_MORNING}`),
              key: userLeaveUnits.HALF_DAY_MORNING
            });
            this.fields.unit.options.push({
              text: this.$t(`models.leaveRequest.attributes.units.${userLeaveUnits.HALF_DAY_EVENING}`),
              key: userLeaveUnits.HALF_DAY_EVENING
            });
          } else {
            this.fields.unit.options.push({text: this.$t(`models.leaveRequest.attributes.units.${unit}`), key: unit});
          }
        });
        this.fields.unit.hidden = false;
      } else if ($event.key === 'unit') {
        switch ($event.value) {
        case leaveUnits.FULL_DAY:
          ['date', 'startTime', 'endTime'].forEach((field) => this.hideField(field));
          this.fields.rangeDate.hidden = false;
          this.fields.rangeDate.rules = [{name: 'required'}];
          break;
        case leaveUnits.HOURS:
          this.hideField('rangeDate');
          this.fields.startTime.hidden = false;
          this.fields.startTime.rules = [{name: 'required'}];

          this.fields.endTime.hidden = false;
          this.fields.endTime.rules = [{name: 'required'}];

          this.fields.date.hidden = false;
          this.fields.date.rules = [{name: 'required'}];
          break;
        default:
          ['rangeDate', 'startTime', 'endTime'].forEach((field) => this.hideField(field));
          this.fields.date.hidden = false;
          this.fields.date.rules = [{name: 'required'}];
          break;
        }
        this.$refs.form.resetValidation();
      }
    },
    onTimeChange($event) {
      if ($event.key === 'startTime') {
        this.fields.endTime.minTime = $event.value ? moment($event.value, 'hh:mm A').add(15, 'minutes').format('HH:mm') : '';
      } else if ($event.key === 'endTime') {
        this.fields.startTime.maxTime = $event.value ? moment($event.value, 'hh:mm A').subtract(15, 'minutes').format('HH:mm') : '';
      }
      this.clearDurationServerErrors();
    },
    onDateChange() {
      ['endTime', 'startTime'].forEach((timepicker) => {
        this.fields[timepicker].minTime = '';
        this.fields[timepicker].maxTime = '';
        this.fields[timepicker].value = null;
      });
      this.clearDurationServerErrors();
    },
    clearDurationServerErrors() {
      ['startTime', 'endTime', 'date', 'rangeDate'].forEach((field) => this.fields[field].serverErrors = []);
    },
    handleSubmit(data) {
      const requestData = {
        user_id: data.user_id,
        user_leave_id: data.user_leave_id,
        unit: data.unit
      };
      const selectedUser = this._.find(this.users.items, (user) => user.id === data.user_id);
      let startTime = selectedUser ? selectedUser.office.businessHoursStart : null;
      let endTime = selectedUser ? selectedUser.office.businessHoursEnd : null;

      switch (data['unit']) {
      case userLeaveUnits.HALF_DAY_MORNING:
        endTime = moment(startTime, 'HH:mm').add((+selectedUser.workingHoursPerDay / 2.0), 'hours').format('HH:mm');
        requestData['start_date'] = moment.utc(moment(`${data['date']} ${startTime}`)).format();
        requestData['end_date'] = moment.utc(moment(`${data['date']} ${endTime}`)).format();
        break;
      case userLeaveUnits.HALF_DAY_EVENING:
        startTime = moment(endTime, 'HH:mm').subtract((selectedUser.workingHoursPerDay / 2.0), 'hours').format('HH:mm');
        requestData['start_date'] = moment.utc(moment(`${data['date']} ${startTime}`)).format();
        requestData['end_date'] = moment.utc(moment(`${data['date']} ${endTime}`)).format();
        break;
      case userLeaveUnits.HOURS:
        requestData['start_date'] = moment.utc(moment(`${data['date']} ${data['start_time']}`)).format();
        requestData['end_date'] = moment.utc(moment(`${data['date']} ${data['end_time']}`)).format();
        break;
      default:
        requestData['start_date'] = moment.utc(moment(`${data['range_date'][0]} ${startTime}`)).format();
        requestData['end_date'] = moment.utc(moment(`${data['range_date'][1] || data['range_date'][0]} ${endTime}`)).format();
        break;
      }
      const request = this.isEditMode ? this.updateLeaveRequest({id: this.$route.params.id, data: requestData}) :
        this.requestNewLeave(requestData);

      request.then((response) => {
        if (this.successCode(response.status)) {
          this.goToUserLeaves(this.fields.userId.value);
        } else {
          response.errors.forEach((error) => {
            const fieldKey = this._.camelCase(error.field);
            if (fieldKey === 'requestedDates' || fieldKey === 'duration') {
              ['date', 'rangeDate', 'startTime', 'endTime'].forEach((field) => {
                if (!this.fields[field].hidden) {
                  if (error.code !== 'blank') {
                    this.fields[field].serverErrors.push(error.message);
                  } else if (this._.isEmpty(this.fields[field].value)) {
                    this.fields[field].serverErrors.push(error.message);
                  }
                }
              });
            } else if (fieldKey === 'leavesRequestsDetails' || fieldKey === 'userLeave') {
              this.fields.userLeaveId.serverErrors.push(error.message);
            } else {
              this.fields[fieldKey].serverErrors.push(error.message);
            }
          });
        }
      });
    },
    hideField(field) {
      if (this.fields[field].type === 'timepicker') {
        this.fields[field].minTime = '';
        this.fields[field].maxTime = '';
      }
      this.fields[field].rules = [];
      this.fields[field].value = this.fields[field].type === 'rangeDatepicker' ? [] : '';
      this.fields[field].hidden = true;
    },
    setDefaultValues() {
      Promise.all([
        this.userOptions(),
        this.userLeaveOptions()
      ]).then(() => {
        this.fields.unit.value = this.isEditMode ? this.userLeavesRequest.unit : '';

        if (this.isEditMode) {
          this.fields.unit.hidden = false;
          switch (this.userLeavesRequest.unit) {
          case leaveUnits.FULL_DAY:
            this.fields.rangeDate.value = [moment(this.userLeavesRequest.startDate).format('YYYY-MM-DD')];
            if (this.userLeavesRequest.endDateWithTimezone !== this.userLeavesRequest.startDateWithTimezone) {
              this.fields.rangeDate.value.push(moment(this.userLeavesRequest.endDate).format('YYYY-MM-DD'));
            }
            this.fields.rangeDate.hidden = false;
            break;
          case leaveUnits.HOURS:
            this.fields.date.value = moment(this.userLeavesRequest.startDate).format('YYYY-MM-DD');
            this.fields.startTime.value = this.userLeavesRequest.startTime;
            this.fields.endTime.value = this.userLeavesRequest.endTime;
            ['date', 'startTime', 'endTime'].forEach((field) => {
              this.fields[field].hidden = false;
            });
            break;
          default:
            this.fields.date.value = moment(this.userLeavesRequest.startDate).format('YYYY-MM-DD');
            this.fields.date.hidden = false;
          }
        } else {
          ['unit', 'date', 'rangeDate', 'startTime', 'endTime'].forEach((field) => this.hideField(field));
        }
      });
    },
    goToUserLeaves(userId) {
      this.$router.push({
        name: 'UserLeaves',
        params: {uuid: userId === this.currentUser.id ? 'me' : userId}
      });
    }
  },
  watch: {
    'fields.date.value': {
      handler() {
        this.clearDurationServerErrors();
      }
    },
    'fields.userLeaveId.value': {
      handler(id) {
        this.fields.unit.options = [];
        const userLeave = this.userLeaves.items.find((item) => +item.id === +id);
        const units = userLeave ? userLeave.unit : [];
        units.forEach((unit) => {
          if (unit === leaveUnits.HALF_DAY) {
            this.fields.unit.options.push({
              text: this.$t(`models.leaveRequest.attributes.units.${userLeaveUnits.HALF_DAY_MORNING}`),
              key: userLeaveUnits.HALF_DAY_MORNING
            });
            this.fields.unit.options.push({
              text: this.$t(`models.leaveRequest.attributes.units.${userLeaveUnits.HALF_DAY_EVENING}`),
              key: userLeaveUnits.HALF_DAY_EVENING
            });
          } else {
            this.fields.unit.options.push({text: this.$t(`models.leaveRequest.attributes.units.${unit}`), key: unit});
          }
        });
      },
      immediate: true
    },
    '$route.params.id': {
      async handler(id) {
        this.setPermission(permissionsList['MANAGE_LEAVE_REQUESTS']);
        this.isEditMode = false;
        if (id) {
          this.isEditMode = true;
          this.title.text = this.$t('layout.accountNavMenu.editLeaveRequest');
          this.getLeaveRequest(this.$route.params.id).then(() => {
            this.selectedUserId = this.userLeavesRequest.user.id;
            this.setDefaultValues();
          });
        } else {
          this.selectedUserId = !this.$route.params.uuid || this.$route.params.uuid === 'me' ? this.currentUser.id : this.$route.params.uuid;
          this.setDefaultValues();
        }
      },
      immediate: true
    }
  },
  destroyed() {
    this.setPermission();
  }
};
</script>
