<template>
<v-card outlined>
  <v-list-item three-line>
    <v-list-item-content>
      <div class="text-right" v-if="isAdmin && canUpdateCard">
        <v-btn icon x-small @click="$emit('open-edit-modal')">
          <v-icon>mdi-pen</v-icon>
        </v-btn>
      </div>
      <v-list-item-title class="text-center mb-1">
        {{ userLeave.name }}
        <h3 class="mt-1">
          {{ userLeave.remainingBalance.hours ?  userLeave.remainingBalance.hours : '-'  }} /
          {{ userLeave.totalBalance.hours }} {{ $t('models.leave.attributes.units.hours') }}
        </h3>
      </v-list-item-title>
      <div class="mb-5 mt-1">
        <v-list-item-subtitle>
          {{ $t('models.userLeave.attributes.pendingApproval') }}
          <b class="text-right">
            {{ userLeave.pendingApproval.convention }} ({{ userLeave.pendingApproval.hours }}hrs)
          </b>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{ $t('models.userLeave.attributes.approved') }}
          <b class="text-right">{{ userLeave.approved.convention }} ({{ userLeave.approved.hours }}hrs)</b>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{ $t('models.userLeave.attributes.rejected') }}
          <b class="text-right">{{ userLeave.rejected.convention }} ({{ userLeave.rejected.hours }}hrs)</b>
        </v-list-item-subtitle>
      </div>
      <v-list-item-subtitle  class="text-right">
        {{ userLeave.startDate }} - {{ userLeave.endDate }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</v-card>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  name: 'LeaveCard',
  props: {
    userLeave: {
      type: Object,
      required: true
    },
    canUpdateCard: {
      type: Boolean,
      default: true
    }
  },
  computed: mapGetters(['isAdmin'])
};
</script>

<style lang="scss" scoped>
  .text-right {
    text-align: right;
    float: right;
  }
</style>
